import React, { FunctionComponent, createContext, useReducer } from 'react';
import { noop } from 'lodash';

type ContractGroups = { [groupKey: string]: boolean };

interface Validation {
  domainName: boolean;
  domainCname: boolean;
  crmAccountId: boolean;
  crmAccountIdRequired: boolean;
  contractGroups: ContractGroups;
}

interface Context {
  state: Validation;
  dispatch: React.Dispatch<{ key: string; value: any; }>;
  validate: (key: string, value: boolean | ContractGroups) => void;
  invalidate: (key: string) => void;
}

const initialState = {
  domainName: false,
  domainCname: false,
  crmAccountId: false,
  crmAccountIdRequired: false,
  // @ts-ignore because Object.fromEntries isn't in our lib
  contractGroups:  {
    'domainType' : false
  }
};

export const ValidationContext = createContext<Context>({
  state: initialState,
  dispatch: noop,
  validate: noop,
  invalidate: noop
});
const reducer = (state: Validation, action: { key: string; value: any }): Validation => {
  console.log('validation', state, action);
  return ({ ...state, [action.key]: action.value });
};

const Validator: FunctionComponent = ({ children }) => {
  const [ state, dispatch ] = useReducer<typeof reducer>(reducer, initialState);

  const validate = (key: string, value: boolean | ContractGroups) => dispatch({ key, value });
  const invalidate = (key: string) => dispatch({ key, value: false });

  return <ValidationContext.Provider value={{ state, dispatch, invalidate, validate }}>
    {children}
  </ValidationContext.Provider>;
};

export default Validator;
